import React, {useState, useEffect} from 'react'
import { faCheck, faPrint, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "../global-components/accordion/accordion"
import data from "./question-data";
import qad from "./questions-to-ask-your-doctor-v7.pdf";

const QuestionBuilder = (props) => {

  const [active, setActive] = useState([]);
  const [all, setAll] = useState(false);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState([]);
  const [initial, setInitial] = useState(false)

  const showResults =(e)=>{
    setVisible([...active]);
    setInitial(true);
  }

  const downloadPdf =(e)=>{
    window.open(qad);
    e.preventDefault();
  }

  const handlePrint =(e)=>{
    window.print();
    e.preventDefault();
  }

  const populateAll = ()=>{
    let dataArr = [];
    if(active.length !== total){
      for (var term of data.terms) {
        dataArr.push(term.slug);
        if(term.children){
          for (var child of term.children) {
            dataArr.push(child.slug);
          }
        }
      }
      setActive([...dataArr]);
      setAll(true);
    }else{
      setActive([]);
      setAll(false);
    }
  }

  const setChildren =(id)=>{
    let index = data.terms.findIndex(x => x.slug === id);
    let children = data.terms[index].children;
    let dataArr = []
    for (var child of children) {
      if(!active.includes(child.slug)){
        dataArr.push(child.slug);
      }
    }
    setActive(active => [...active, ...dataArr]);
  }

  const removeChildren =(id)=>{
    let index = data.terms.findIndex(x => x.slug === id);
    let children = data.terms[index].children;
    let dataArr = [...active];
    for(var child of children){
      dataArr = dataArr.filter(item => item !== child.slug)
    }
    let arr = dataArr.filter(item => item !== id)
    setActive(arr);
  }

  const setParent =(parent)=>{
    if(!active.includes(parent)){
      setActive(active => [...active, parent]);
    }
  }

  const removeParent =(parent, id)=>{
    let parentArray = active.filter(item => item !== parent)
    let childArray = parentArray.filter(item => item !== id)
    setActive([...childArray]);
  }

  const handleClick=(e, id)=>{
    id = (id.classList >1)? e.currentTarget.classList[0] : id
    let isChild = (e.currentTarget.classList[2] === 'isChild')? true : false;
    let parent = (isChild)? e.currentTarget.classList[0] : false;
    let hasChildren = (e.currentTarget.classList[1] === 'hasChildren')? true : false;
    if(!active.includes(id)){
      setActive(active => [...active, id]);
      if(hasChildren){
        setChildren(id);
      }
      if(isChild){
        setParent(parent);
      }
    }else{
      let arr = active.filter(item => item !== id)
      setActive([...arr]);
      if(hasChildren){
        removeChildren(id);
      }
      if(isChild){
        setTimeout(function(){
          let activeChildren = document.querySelectorAll(`.${parent}.isActive`).length;
          if(activeChildren === 0){
            removeParent(parent, id);
          }
        },10);
      }
      if(setAll){
        setAll(false);
      }
    }
  }

  useEffect(() =>{
    let total = 0;
    for (var term of data.terms) {
      total++;
      if(term.children){
        for (var child of term.children) {
          total++;
        }
      }
    }
    setTotal(total);
  },[]);

  useEffect(() => {
    if(active.length){
      if(active.length === total){
        setAll(true);
      }
    }
  }, [active, total]);

  useEffect(() => {
    if(visible.length ===0 && initial){
      setInitial(false);
    }
    if(visible.length > 0){
      let sh = document.getElementById('scroll-head').offsetTop;
      window.scroll({
        top: 0 + ( sh - 300),
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [visible, initial]);


  return (
    <>
      <div className="row row--inner lg_mt-4 mt-3">
        <div className="columns">
          <div className="question-builder">
            <div className="question-builder__header">
              <p><strong>I want to find questions to ask the doctor about…</strong></p>
            </div>
            <div className="question-builder__body">
              <div className="row">
                <div className="columns">
                  <ul className="ul--standard">
                    <li>
                      <button className="all" onClick={(e) => populateAll(e,'all', false)}>
                        <span>
                          {all &&
                            <FontAwesomeIcon icon={faCheck}/>
                          }
                        </span>
                        <strong>
                          {all &&
                            <>
                              Deselect All
                            </>
                          }
                          {!all &&
                            <>
                              Select All
                            </>
                          }
                        </strong>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="columns small-12 large-6">
                  <ul className="ul--standard">
                  {data.terms.slice(0,5).map((term, index) => (
                    <li key={index}>
                      <button className={term.slug} onClick={(e) => handleClick(e, term.slug)}>
                        <span>
                          {active.includes(term.slug) &&
                            <FontAwesomeIcon icon={faCheck}/>
                          }
                        </span>
                        {term.title}
                      </button>
                    </li>
                  ))}
                  </ul>
                </div>
                <div className="columns small-12 large-6">
                  <ul className="ul--standard">
                    {data.terms.slice(5,100).map((term, index) => (
                      <li key={index}>
                        <button className={term.children? `${term.slug} hasChildren` : term.slug} onClick={(e) => handleClick(e, term.slug)}>
                          <span>
                            {active.includes(term.slug) &&
                              <FontAwesomeIcon icon={faCheck}/>
                            }
                          </span>
                          {term.title}
                        </button>
                        {term.children &&
                          <ul>
                          {term.children.map((child, index) => (
                            <li key={index}>
                              <button className={`${term.slug} ${child.slug} isChild` + (active.includes(child.slug)? ` isActive`: ``)} onClick={(e) => handleClick(e, child.slug)}>
                                <span>
                                  {active.includes(child.slug) &&
                                    <FontAwesomeIcon icon={faCheck}/>
                                  }
                                </span>
                                {child.title}
                              </button>
                            </li>
                          ))}
                          </ul>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="row lg_mt-2 mt-2">
                <div className="columns text-center">
                  <button data-gtm-event-category="Question Builder" data-gtm-event-action="Click" data-gtm-event-label="Show Results (ask doctor about)" disabled={!(active.length > 0 || initial)} className="question-builder__submit h6 uppercase jost-med" onClick={(e)=>showResults(e)}>
                    {initial &&
                      <span>update results</span>
                    }
                    {!initial &&
                      <span>show results</span>
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="scroll-head" className={(visible.length > 0)? `lg_mt-4 mt-2` : `visually-hidden`}>
        <div className="row row--inner lg_mb-4 align-middle small-col-reverse">
          <div className="columns large-4 small-12 mt-2">
            <h3 className="tc-barney jost-med h3">Questions to ask your doctor</h3>
          </div>
          <div className="columns large-text-right large-8 small-12 mt-2 mb-2">
            <button className="question-builder__submit jost-med" onClick={(e)=> handlePrint(e)}>Print my questions <FontAwesomeIcon icon={faPrint}/></button>
            <button className="question-builder__submit jost-med mt-1" onClick={(e)=> downloadPdf(e)} data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Doctor Discussion Guide">Download all questions <FontAwesomeIcon icon={faDownload}/></button>
          </div>
        </div>

        {data.terms.map((term, index) => (
        <div key={index} className={visible.includes(term.slug)? `row row--inner lg_mt-2`:`visually-hidden`}>
          <div className="columns">
            <Accordion className="mt-2">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer>{term.question}</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <div className="row">
                    {term.questions &&
                      <>
                      {term.questions.map((question, index) => (
                        <div className="columns large-12 small-12 lg_mt-1" key={index}>
                            <p>{question}</p>
                        </div>
                      ))}
                      </>
                    }
                    {term.children &&
                      <>
                        {term.children.map((child, index) => (
                          <React.Fragment key={index}>
                            {visible.includes(child.slug) &&
                              <>
                              <div className={`columns large-12 lg_mb-1 lg_mt-1`}>
                                <h2 className="tc-viridian h4 jost-med">{child.question}</h2>
                              </div>
                              {child.questions &&
                                <>
                                  {child.questions.map((question, index) => (
                                    <div className="columns large-12 small-12 lg_mb-1" key={index}>
                                      <p>{question}</p>
                                    </div>
                                  ))}
                                </>
                              }
                            </>
                          }
                          </React.Fragment>
                        ))}
                      </>
                    }
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      ))}
      </div>

    </>
  )
}


export default QuestionBuilder
