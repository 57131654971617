import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import QuestionBuilder from "../../components/template-partials/newly-diagnosed/question-builder"
import NewlyDiagnosedSubmenu from "../../components/template-partials/newly-diagnosed/newly-diagnosed-submenu"

import {UpNextCard, ExploreCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"

const QuestionsForDoctorPage = () => {
  return (
    <Layout>
      <SEO title="Neuroblastoma Questions to Ask Your Oncologist" description="Your child’s healthcare team is your greatest asset in learning about neuroblastoma and understanding each step along the treatment journey. Use our question builder to help facilitate informed discussions about your child’s care." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Questions to Ask Your Doctor">
        <h1 className="section__heading section__heading--green h1">Questions to Ask Your Doctor</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Newly Diagnosed`}>
              <NewlyDiagnosedSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="tc-barney h2 jost-med">Questions to ask your child’s doctor about neuroblastoma</h2>
                <p>Throughout your child’s neuroblastoma journey, you, your family, and your loved ones will learn a great deal about the disease, diagnosis, and treatment.  </p>
                <p>Your child’s healthcare team is your best resource when learning about and understanding each step. It is important to be prepared with questions to ask your child’s oncologists to help make the most of your time with the experts and facilitate informed treatment decisions.  </p>
                <p><strong>Use the question builder below to help prepare for conversations about your child's care. </strong></p>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">Neuroblastoma questions to ask your doctor</h3>
            <h4 className="tc-viridian h4 jost-med">Select a point in the treatment journey to put together a list of questions to bring to your next meeting with your child’s healthcare team. </h4>
            <p><Link to="/support-and-community/glossary/">If you are not sure what some of the terms in the questions mean, please visit the glossary page.</Link></p>
          </div>
        </div>

        <QuestionBuilder/>

      </Section>

      <InteriorPageFooter>


        {/* <UpNextCard topic="Glossary of Terms" title="Glossary of terms" lead="Learn the meanings of key words related to neuroblastoma and treatment." url="/support-and-community/glossary/" /> */}
        
      <UpNextCard topic="Non–High-Risk (Low- or Intermediate-Risk) Treatment" title="Learn more about your child's treatment for non–high-risk neuroblastoma" lead="See what therapies are used to treat non–high-risk (low- and intermediate-risk) neuroblastoma." url="/treatment/non-high-risk-neuroblastoma/" />

      <ExploreCard title="Looking for additional resources?" lead="Find helpful resources for you and your child to use during your neuroblastoma journey." url="/support-and-community/downloadable-resources/"/>

      </InteriorPageFooter>

    </Layout>
  )
}

export default QuestionsForDoctorPage
